import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'beverages',
  formId: 'Drinks%20(general)',
  title: 'Beverages',
  subTitle: 'Stay hydrated and happy!',
  icon: '/icons/drinks.svg',
  image: '/assets/images/sunrise/drinks_supermercat.png',
  theme: 'sunrise-drinks',
  sections: [
    {
      sectionId: 'hero',
      label: 'Beverages',
      title: 'Stay hydrated and happy!',
      description:
        'A key part of a well-run office is having a nice selection of beverages. It’s difficult to go through the day if you don’t have the basics. We’ll make sure you have everything you need! ',
      image: '/assets/images/sunrise/drinks_supermercat.png',
      icon: '/icons/drinks.svg',
      buttonText: 'Request a proposal',
    },
    {
      sectionId: 'options',
      title: 'Get your drink on...!',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/drinks/service_1.svg',
          title: 'Soft drinks',
          description: '',
        },
        {
          icon: '/assets/images/services/drinks/service_2.svg',
          title: 'Fresh juice',
          description: '',
        },
        {
          icon: '/assets/images/services/drinks/service_3.svg',
          title: 'Cold beers',
          description: '',
        },
        {
          icon: '/assets/images/services/drinks/service_4.svg',
          title: 'Wine and spirits',
          description: '',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Beverages are just a few steps away',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/drinks/journey_1.svg',
          title: 'Tell us what you like',
          description: 'We have a vast variety of everything. Tell us what gets your wheels turning.',
        },
        {
          icon: '/assets/images/services/drinks/journey_2.svg',
          title: 'Receive proposal',
          description: 'We’ll create a detailed proposal to serve your cravings!',
        },
        {
          icon: '/assets/images/services/drinks/journey_3.svg',
          title: 'Accept!',
          description: 'You’re now set to please the thirsty hard workers out there.',
        },
        {
          icon: '/assets/images/services/drinks/journey_4.svg',
          title: 'Cheers!',
          description: 'That’s it! You can now raise your glasses!',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Why companies love beverages from Good Monday!',
      description: '',
      image: '/assets/images/services/drinks/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-layers-outline',
          title: 'Fully stocked',
          description: 'Drinks are a part of our “fully stocked” service so you never run out of ice cold beverages.',
        },
        {
          icon: 'eva eva-people-outline',
          title: 'A vast network of connoisseurs',
          description: 'We’ll help guide you towards options that fit you the best!',
        },
        {
          icon: 'eva eva-book-outline',
          title: 'Fits all wallets',
          description: 'We have something for all budgets and occasions.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Did you know that with Good Monday’s ‘fully stocked’ you’ll never run out of drinks again?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Request a proposal',
    },
  ],
}
